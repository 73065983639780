<template>
  <div class="level-card-wrapper">
    <div class="content">
      <div class="card-content-wrapper">
        <div v-for="item in ibList" :key="item.id">
          <ibCard
            :name="item.name"
            :accNumber="item.account"
            :isActiveNo="isActiveNo"
            @choose="chooseIB(item)"
          ></ibCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ibCard from '@/components/ibLevel/ibCard';

export default {
  name: 'levelList',
  components: { ibCard: ibCard },
  props: { level: Number, ibList: Array },
  data() {
    return {
      isActiveNo: null
    };
  },
  watch: {
    ibList: {
      immediate: true,
      handler(val) {
        const firstIB = val.length > 0 ? val[0] : null;
        if (this.level === 1 && firstIB && firstIB.account) {
          this.chooseIB(firstIB);
        }
      }
    }
  },
  methods: {
    chooseIB(item) {
      this.isActiveNo = item.account;
      this.$emit('curAccNumberCallback', { isActiveNo: this.isActiveNo, isActiveLevel: this.level });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/levelList.scss';
</style>
