<template>

  <div id="ib_level">

    <div class="wrapper_main">

      <div class="wrapper_content">

        <div class="content_title">

          <h2>{{ $t('menu.iblevel') }}</h2>

          <p></p>

        </div>

        <div class="ib_level_header">

          <div class="select">

            <el-form>

              <AccountNumber :label="$t('ibAccounts.ibAccount')" @accountCallback="handleMainAccSelect"></AccountNumber>

            </el-form>

          </div>

        </div>

        <div class="ib_level_content">

          <div class="title mainTitle">{{ $t('multiib.level', { level: 1 }) }}</div>

          <div class="ib_level_content--upper">

            <el-card :body-style="{ padding: '0px' }" class="ib-level-card" shadow="hover">

              <div class="ib-level-image-wrapper">

                <img src="@/assets/uploads/bigProfile.png" class="ib-image--upper" />

              </div>

              <div class="info">

                <el-row class="ib_level_content-subtitle">

                  <span>{{ accountID }}</span>

                </el-row>

                <el-row class="ib_level_content-title">

                  <span>{{ ibName }}</span>

                </el-row>

              </div>

            </el-card>

          </div>

          <div class="sub_ib" v-for="(item, index) in items" :key="item.account">

            <div class="title" :class="{ openTitle: openLevel == index }" v-if="item.length !== 0" @click="open(index)">
               {{ $t('multiib.level', { level: index + 2 }) }}
              <img />

            </div>

            <levelList
              class="levelLists"
              :ibList="item"
              :level="index + 1"
              @curAccNumberCallback="setCurAccNumber"
            ></levelList>

          </div>

          <div v-if="!items.length && !isActiveLevel" class="ib_level--alert">

            <p>{{ $t('multiib.noib') }}</p>

          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
import AccountNumber from '@/components/form/AccountNumber';
import levelList from '@/components/ibLevel/levelList';
import { apiIbAccounts } from '@/resource';

export default {
  name: 'IbLevel',
  components: {
    levelList: levelList,
    AccountNumber
  },
  data() {
    return {
      userId: this.$store.state.common.CUID,
      ibName: this.$store.state.common.userName,
      items: [],
      isActiveNo: '',
      itemsDetails: {},
      isActiveNoItems: [],
      isActiveLevel: null,
      openLevel: null
    };
  },
  mounted() {
    /** 判斷尺寸 */
    const vm = this;
    $(window).resize(function() {
      const matches = window.matchMedia('(max-width: 550px)').matches;
      var x = document.getElementsByClassName('levelLists');
      for (let i = 0; i < x.length; i++) {
        x[i].style.display = matches ? 'none' : 'block';
      }
    });
  },
  methods: {
    open(index) {
      var x = document.getElementsByClassName('levelLists');
      for (let i = 0; i < x.length; i++) {
        x[i].style.display = 'none';
      }

      if (this.openLevel != index) {
        x[index].style.display = 'block';
        this.openLevel = index;
      } else {
        this.openLevel = null;
      }
    },
    handleMainAccSelect() {
      this.itemsDetails = {};
      this.isActiveLevel = null;
      this.isActiveNo = this.accountID;
    },
    setCurAccNumber(value) {
      this.isActiveNo = value['isActiveNo'];
      this.isActiveLevel = value['isActiveLevel'];
    },
    getCurAccNoItems(value) {
      apiIbAccounts('sub_ibs', {
        userId: this.userId,
        account: parseInt(value)
      }).then(resp => {
        if (resp.data.code === 0 && resp.data.data.length > 0) {
          this.isActiveNoItems = resp.data.data
            .sort((a, b) => a.account - b.account)
            .sort((a, b) => a.name.localeCompare(b.name));
        } else {
          this.isActiveNoItems = [];
        }
      });
    }
  },
  computed: {
    accountID() {
      return this.$store.state.behavior.AID;
    }
  },
  watch: {
    isActiveNo(val) {
      this.getCurAccNoItems(val);
    },
    isActiveNoItems: {
      handler(val) {
        this.items = [];
        if (!this.isActiveLevel) this.isActiveLevel = 0;
        let level = parseInt(this.isActiveLevel) + 1; //make index 0 to level 1

        for (let [key, value] of Object.entries(this.itemsDetails)) {
          if (this.isActiveLevel < parseInt(key)) delete this.itemsDetails[key];
        }

        if (this.isActiveNoItems.length !== 0) {
          this.itemsDetails[level] = this.isActiveNoItems;
        }
        this.items = Object.values(this.itemsDetails);
        console.log('Details:', this.itemsDetails);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/iblevel.scss';
</style>

