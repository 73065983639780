<template>
  <div @click="chooseHandler" class="ib_level_content--upper">
    <el-card
      :body-style="{ padding: '0px' }"
      class="ib-level-card"
      :class="{ 'ib-card__active': isActiveNo === accNumber }"
    >
      <div class="ib-level-image-wrapper">
        <img v-if="isActiveNo === accNumber" src="@/assets/uploads/group-6-copy@3x.png" class="ib-image--upper" />
        <img v-else src="@/assets/uploads/bigProfile.png" class="ib-image--upper" />
      </div>
      <div class="info">
        <el-row class="ib_level_content-subtitle">
          <span>{{ accNumber }}</span>
        </el-row>
        <el-row class="ib_level_content-title">
          <span>{{ name }}</span>
        </el-row>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'ibCard',
  props: {
    name: String,
    accNumber: Number,
    isActiveNo: Number
  },
  methods: {
    chooseHandler() {
      this.$emit('choose');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/ibcard.scss';
</style>
